<template>
	<Dialog :dialog="newFolderDialog" :dialog-width="400">
		<template v-slot:title> Create new Folder </template>
		<template v-slot:body>
			<text-input v-model="new_folder_model" hide-details placeholder="Enter Name..."></text-input>
		</template>
		<template v-slot:action>
			<v-btn depressed :disabled="loading" tile v-on:click="$emit('close', true)">Cancel</v-btn>
			<v-btn
				depressed
				:disabled="loading"
				:loading="loading"
				tile
				color="blue darken-4 white--text"
				v-on:click="new_folder_create()"
				>Save</v-btn
			>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { POST } from "@/core/services/store/request.module";

export default {
	name: "new-folder-dialog",
	props: {
		newFolderDialog: {
			type: Boolean,
			default: false,
		},
		linkedId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			loading: false,
			new_folder_model: null,
		};
	},
	methods: {
		new_folder_create() {
			this.loading = true;
			this.$store
				.dispatch(POST, {
					url: `folders/create`,
					data: { name: this.new_folder_model, linked_id: this.linkedId },
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		Dialog,
		"text-input": TextInput,
	},
};
</script>
