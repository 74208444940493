<template>
	<restore-dialog :dialog="restoreDialog" :dialog-width="650" dense>
		<template v-slot:title>Restore Confirmation</template>
		<template v-slot:body>
			<v-container class="pa-2">
				<v-layout>
					<v-flex md2>
						<v-img contain :src="$assetURL('media/misc/restore-animated.gif')"></v-img>
					</v-flex>
					<v-flex md10 class="ml-5" style="min-height: 170px">
						<br />
						<p class="text-truncate font-level-2-bold">
							Are you sure you want to restore this
							{{ toBeRestore.type }} to its original location ?
						</p>
						<div v-if="toBeRestore" class="mt-2 d-flex flex-row flex-nowrap">
							<v-icon color="blue darken-4" x-large class="mr-2">{{ get_icon(toBeRestore) }}</v-icon>
							<div>
								<p class="text-truncate font-level-3-bold py-2">
									{{ toBeRestore.name }}
								</p>
								<p v-if="toBeRestore.created" class="text-truncate font-level-2 py-1">
									Date Created:
									<span class="font-level-2-bold">{{ formatDateTime(toBeRestore.created.datetime) }}</span>
								</p>
								<p v-if="toBeRestore.deleted" class="text-truncate font-level-2 py-1">
									Date Deleted:
									<span class="font-level-2-bold">{{ formatDateTime(toBeRestore.deleted.datetime) }}</span>
								</p>
								<p v-if="toBeRestore.deleted" class="text-truncate font-level-2 py-1">
									Deleted By:
									<span class="font-level-2-bold">{{ toBeRestore.deleted.user }}</span>
								</p>
							</div>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="restore_file()" color="blue darken-4 text-white"
				>Yes ! Restore</v-btn
			>
			<v-btn depressed tile v-on:click="$emit('close', true)">No ! Cancel</v-btn>
		</template>
	</restore-dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { PATCH } from "@/core/services/store/request.module";
export default {
	name: "restore-confirmation",
	props: {
		restoreDialog: {
			type: Boolean,
			default: false,
		},
		toBeRestore: {
			type: Object,
			default() {
				return {
					uuid: null,
					type: null,
					name: null,
					created: {
						datetime: null,
					},
				};
			},
		},
	},
	components: {
		"restore-dialog": Dialog,
	},
	data() {
		return {
			loading: false,
		};
	},
	methods: {
		restore_file() {
			this.loading = true;
			this.$store
				.dispatch(PATCH, {
					url: `files/${this.toBeRestore.uuid}/restore`,
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
