<template>
	<Dialog :dialog="unlockFolderDialog">
		<template v-slot:title> Unlock Folder {{ folderUnlock.name }}</template>
		<template v-slot:body>
			<v-layout>
				<v-flex md4>
					<label for="password">Password</label>
				</v-flex>
				<v-flex md8>
					<text-input
						:append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
						v-on:click:append="show_password = !show_password"
						:type="show_password ? 'text' : 'password'"
						id="password"
						v-model="password"
						hide-details
						placeholder="Enter Password..."
						:disabled="loading"
						:loading="loading"
						v-on:keyup-enter="unlock_folder()"
					></text-input>
				</v-flex>
			</v-layout>
		</template>
		<template v-slot:action>
			<v-btn :disabled="loading" depressed tile v-on:click="$emit('close', true)">Cancel</v-btn>
			<v-btn
				:disabled="loading"
				:loading="loading"
				depressed
				tile
				color="blue darken-4 white--text"
				v-on:click="unlock_folder()"
				>Unlock</v-btn
			>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { QUERY } from "@/core/services/store/request.module";

export default {
	name: "folder-unlock-dialog",
	props: {
		unlockFolderDialog: {
			type: Boolean,
			default: false,
		},
		folderUnlock: {
			type: Object,
			default() {
				return {
					uuid: null,
					type: null,
					name: null,
					created: {
						datetime: null,
					},
				};
			},
		},
	},
	data() {
		return {
			password: null,
			loading: false,
			show_password: false,
		};
	},
	watch: {
		unlockFolderDialog(param) {
			if (param) {
				this.password = null;
			}
		},
	},
	methods: {
		unlock_folder() {
			this.loading = true;
			this.$store
				.dispatch(QUERY, {
					url: `folders/${this.folderUnlock.uuid}/unlock`,
					data: { password: this.password },
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", this.folderUnlock);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		Dialog,
		"text-input": TextInput,
	},
};
</script>
