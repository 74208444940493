<template>
	<v-combobox
		ref="search"
		:items="items"
		hide-no-data
		hide-selected
		clearable
		autocomplete="off"
		hide-details
		outlined
		:placeholder="raw_placeholder"
		v-model="search_model"
		:search-input.sync="search_sync"
		style="max-width: 450px; min-width: 400px"
		:menu-props="{
			'z-index': 10,
		}"
		@focus="is_focused = true"
		@blur="is_focused = false"
		@keydown.enter="start_search()"
		@click:clear="clear_search()"
		@update:list-index="row_select($event)"
	>
		<template #prepend-inner>
			<v-icon :color="!is_focused ? 'grey' : undefined" class="ml-1 mr-2"> mdi-magnify </v-icon>
		</template>
		<template #item="{ item }">
			<v-list-item-content v-on:click="row_select(item)">
				<v-list-item-title v-text="item"></v-list-item-title>
			</v-list-item-content>
		</template>
	</v-combobox>
</template>

<script>
import { map } from "lodash";
import { GET } from "@/core/services/store/request.module";

export default {
	name: "top-search-bar",
	props: {
		placeholder: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			search_sync: null,
			search_model: null,
			is_focused: false,
			is_loading: true,
			tags: [],
			users: [],
			items: [],
		};
	},
	watch: {
		search_sync(param) {
			if (param) {
				if (param.charAt(0) == "#") {
					this.items = this.tags;
				} else if (param.charAt(0) == "@") {
					this.items = this.users;
				} else {
					this.items = [];
				}
			} else {
				this.items = [];
			}
		},
	},
	methods: {
		row_select(row) {
			console.log({ row });
		},
		clear_search() {
			this.items = [];
			this.search_sync = null;
			this.search_model = null;
		},
		start_search() {
			if (this.search_sync) {
				if (this.search_sync.charAt(0) == "#" || this.search_sync.charAt(0) == "@") {
					return false;
				}
			}

			if (this.search_model) {
				if (this.search_model.charAt(0) == "#" || this.search_model.charAt(0) == "@") {
					return false;
				}
			}

			console.log({ search_sync: this.search_sync });
			console.log({ search_model: this.search_model });
			//
		},
		get_search_options() {
			this.$store
				.dispatch(GET, { url: "search/options" })
				.then((response) => {
					this.tags = map(response.tags, "name");
					this.users = map(response.users, "display_name");
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.is_loading = false;
				});
		},
	},
	mounted() {
		this.get_search_options();
	},
	computed: {
		raw_placeholder() {
			return this.is_focused ? "Start Typing then press enter" : "Search " + this.placeholder + "...";
		},
	},
};
</script>
