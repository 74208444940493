<template>
	<v-textarea
		:id="id"
		ref="input-text-area"
		:label="label"
		:readonly="readonly"
		:loading="loading"
		:auto-grow="autoGrow"
		:disabled="disabled"
		:hide-details="hideDetails"
		outlined
		:rows="rows"
		:rules="rules"
		class="pt-0"
		:class="{ 'mt-3': !dense }"
		v-model="textareainput"
		v-on:keydown.enter.exact.prevent="$emit('change', true)"
		v-on:change="$emit('change', true)"
		v-on:blur="$emit('blur', true)"
		:placeholder="placeholder"
	></v-textarea>
</template>
<script>
export default {
	name: "textarea-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		rows: {
			type: Number,
			default: 3,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		label: {
			type: String,
			default: null,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		autoGrow: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textareainput: null,
		};
	},
	methods: {
		block_enter(event) {
			event.preventDefault();
		},
	},
	watch: {
		value() {
			this.textareainput = this.value;
		},
		textareainput() {
			this.$emit("input", this.textareainput);
		},
	},
	mounted() {
		this.textareainput = this.value;
	},
};
</script>
