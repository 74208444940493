<template>
	<empty-dialog :dialog="emptyDialog" :dialog-width="500" dense>
		<template v-slot:title>Delete Forever Confirmation</template>
		<template v-slot:body>
			<v-container class="pa-2">
				<v-layout style="min-height: 100px">
					<v-flex md3>
						<v-img contain :src="$assetURL('media/misc/delete-animated.gif')"></v-img>
					</v-flex>
					<v-flex md9 class="ml-5">
						<br />
						<p class="text-truncate font-level-2-bold">
							All items in the bin will be deleted forever.<br /><br />You won't be able to restore them.
						</p>
					</v-flex>
				</v-layout>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="loading"
				:loading="loading"
				depressed
				tile
				v-on:click="empty_file()"
				color="blue darken-4 text-white"
				>Yes ! Delete</v-btn
			>
			<v-btn :disabled="loading" depressed tile v-on:click="$emit('close', true)">No ! Cancel</v-btn>
		</template>
	</empty-dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { PATCH } from "@/core/services/store/request.module";
export default {
	name: "empty-confirmation",
	props: {
		emptyDialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		"empty-dialog": Dialog,
	},
	data() {
		return {
			loading: false,
		};
	},
	methods: {
		empty_file() {
			this.loading = true;
			this.$store
				.dispatch(PATCH, {
					url: `bin/empty`,
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
