<template>
	<manage-tag :dialog="tagDialog" dense>
		<template v-slot:title>Manage Tag</template>
		<template v-slot:body>
			<v-container class="py-5 px-10">
				<table style="table-layout: fixed" width="100%">
					<tr>
						<td width="100%">
							<tag-auto-complete id="manage-tag" v-model="tags"></tag-auto-complete>
						</td>
					</tr>
				</table>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn
				:disabled="loading"
				:loading="loading"
				depressed
				tile
				v-on:click="update_tags()"
				color="blue darken-4 text-white"
				>Save</v-btn
			>
			<v-btn :disabled="loading" depressed tile v-on:click="$emit('close', true)">Cancel</v-btn>
		</template>
	</manage-tag>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TagAutoComplete from "@/view/components/TagAutoComplete";

export default {
	name: "btx-manage-tag",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		tagDialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		"manage-tag": Dialog,
		"tag-auto-complete": TagAutoComplete,
	},
	watch: {
		value() {
			this.tags = this.value;
		},
		tagDialog(param) {
			if (param) {
				this.tags = this.value;
			}
		},
	},
	data() {
		return {
			loading: false,
			tags: [],
		};
	},
	methods: {
		update_tags() {
			this.$emit("submit", this.tags);
		},
	},
	mounted() {
		this.tags = this.value;
	},
};
</script>
