<template>
	<Dialog :dialog="renameDialog">
		<template v-slot:title> Rename </template>
		<template v-slot:body>
			<text-input v-model="rename_model" hide-details placeholder="Enter Name..."></text-input>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close', true)">Cancel</v-btn>
			<v-btn depressed tile color="blue darken-4 white--text" v-on:click="rename_file()">Save</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import { PATCH } from "@/core/services/store/request.module";

export default {
	name: "rename-dialog",
	props: {
		renameDialog: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: "other",
		},
		toBeRename: {
			type: Object,
			default() {
				return {
					uuid: null,
					type: null,
					name: null,
					created: {
						datetime: null,
					},
				};
			},
		},
	},
	data() {
		return {
			rename_model: null,
		};
	},
	watch: {
		toBeRename() {
			this.init_rename();
		},
	},
	mounted() {
		this.init_rename();
	},
	methods: {
		init_rename() {
			if (this.toBeRename.name) {
				const name = this.toBeRename.name;
				if (name) {
					this.rename_model = name.split(".").slice(0, -1).join(".");
				}
				if (!this.rename_model) {
					this.rename_model = this.toBeRename.name;
				}
			}
		},
		rename_file() {
			this.loading = true;
			let requestURL = `files/${this.toBeRename.uuid}/rename`;
			if (this.type == "tag") {
				requestURL = `tag-listing/${this.toBeRename.uuid}/rename`;
			}
			this.$store
				.dispatch(PATCH, {
					url: requestURL,
					data: { name: this.rename_model },
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		Dialog,
		"text-input": TextInput,
	},
};
</script>
