<template>
	<Dialog :dialog="shareDialog" :dialog-width="dialogWidth">
		<template v-slot:title>Share with people</template>
		<template v-slot:body>
			<v-container>
				<v-row class="mx-0 members-list">
					<v-col cols>
						<search-members multiple :default-member="defaultMember" v-model="members"></search-members>
						<div
							class="mt-5"
							style="max-height: calc(100vh - 300px); min-height: calc(100vh - 400px); overflow: auto"
						>
							<v-list two-line>
								<v-list-item
									v-if="defaultMember"
									:class="{
										'border-bottom-light-grey': members.length,
									}"
								>
									<v-list-item-action class="drive-action-btn">
										<v-btn icon color="red lighten-1" disabled>
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-list-item-action>
									<v-list-item-avatar>
										<img
											:src="defaultMember.profile ? defaultMember.profile : $assetURL('media/users/blank.png')"
										/>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-html="defaultMember.display_name"></v-list-item-title>
										<v-list-item-subtitle v-html="defaultMember.email"></v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action>
										<v-btn color="blue darken-4 white--text" class="text-capitalize" text> Owner </v-btn>
									</v-list-item-action>
								</v-list-item>
								<v-list-item
									v-for="(item, index) in members"
									:key="index"
									:value="item.id"
									:class="{
										'border-bottom-light-grey': members[index + 1],
									}"
								>
									<v-list-item-action class="drive-action-btn">
										<v-btn icon color="red lighten-1" v-on:click.stop.prevent="delete_member(item)">
											<v-icon>mdi-close</v-icon>
										</v-btn>
									</v-list-item-action>
									<v-list-item-avatar>
										<img :src="item.profile ? item.profile : $assetURL('media/users/blank.png')" />
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-html="item.display_name"></v-list-item-title>
										<v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
									</v-list-item-content>
									<v-list-item-action v-if="false">
										<v-menu offset-y>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													color="blue darken-4 white--text"
													v-bind="attrs"
													v-on="on"
													class="text-capitalize"
												>
													{{ item.permission }}
													<v-icon small>mdi-arrow-down</v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item v-on:click="item.permission = 'viewer'">
													<v-list-item-title>Viewer</v-list-item-title>
												</v-list-item>
												<v-list-item v-on:click="item.permission = 'editor'">
													<v-list-item-title>Editor</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</v-list-item-action>
								</v-list-item>
							</v-list>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="$emit('close', true)">Cancel</v-btn>
			<v-btn depressed tile class="blue darken-4 white--text" v-on:click="submit()">Done</v-btn>
		</template>
	</Dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import SearchMembers from "@/view/components/SearchMembers";
import { toSafeInteger, findIndex } from "lodash";

export default {
	model: {
		prop: "value",
		event: "input",
	},
	data() {
		return {
			members: [],
		};
	},
	components: {
		Dialog,
		"search-members": SearchMembers,
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
		shareDialog: {
			type: Boolean,
			default: false,
		},
		defaultMember: {
			type: Object,
			default() {
				return {
					id: 0,
					permission: null,
					title: null,
					first_name: null,
					last_name: null,
					phone_number: null,
					display_name: null,
					email: null,
					profile: null,
				};
			},
		},
	},
	watch: {
		members: {
			deep: true,
			immediate: true,
			handler() {
				this.emit_value();
			},
		},
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.members = param;
			},
		},
	},
	methods: {
		emit_value() {
			this.$emit("input", this.members);
			this.$emit("change", this.members);
		},
		submit() {
			this.$emit("submit", this.members);
			this.$emit("close", true);
		},
		delete_member(member) {
			const index = findIndex(this.members, { id: member.id });
			this.members.splice(index, 1);
		},
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
