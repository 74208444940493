<template>
	<delete-dialog :dialog="deleteDialog" :dialog-width="600" dense>
		<template v-slot:title>Delete Confirmation</template>
		<template v-slot:body>
			<v-container class="pa-2">
				<v-layout>
					<v-flex md3>
						<v-img contain :src="$assetURL('media/misc/delete-animated.gif')"></v-img>
					</v-flex>
					<v-flex md9 class="ml-5">
						<p class="text-truncate font-level-2-bold">
							Are you sure you want to move this
							{{ toBeDelete.type }} to the bin ?
						</p>
						<div v-if="toBeDelete" class="mt-2 d-flex flex-row flex-nowrap">
							<v-icon color="blue darken-4" x-large class="mr-4">{{ get_icon(toBeDelete) }}</v-icon>
							<div>
								<p class="text-truncate font-level-3-bold py-2">
									{{ toBeDelete.name }}
								</p>
								<p v-if="toBeDelete.created" class="text-truncate font-level-2 py-1">
									Date Created:
									<span class="font-level-2-bold">{{ formatDateTime(toBeDelete.created.datetime) }}</span>
								</p>
								<v-checkbox
									v-if="!isBin"
									dense
									hide-details
									v-model="permanent_delete"
									:true-value="1"
									:false-value="0"
									color="red lighten-1"
									class="secondary-label"
									label="Permanently Delete ?"
								></v-checkbox>
							</div>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</template>
		<template v-slot:action>
			<v-btn depressed tile v-on:click="delete_file()" color="red lighten-1 text-white"
				>Yes ! Delete</v-btn
			>
			<v-btn depressed tile v-on:click="$emit('close', true)">No ! Cancel</v-btn>
		</template>
	</delete-dialog>
</template>

<script>
import Dialog from "@/view/components/Dialog";
import { DELETE } from "@/core/services/store/request.module";
export default {
	name: "delete-confirmation",
	props: {
		deleteDialog: {
			type: Boolean,
			default: false,
		},
		isBin: {
			type: Boolean,
			default: false,
		},
		toBeDelete: {
			type: Object,
			default() {
				return {
					uuid: null,
					type: null,
					name: null,
					created: {
						datetime: null,
					},
				};
			},
		},
	},
	components: {
		"delete-dialog": Dialog,
	},
	watch: {
		isBin(param) {
			if (param) {
				this.permanent_delete = 1;
			}
		},
	},
	data() {
		return {
			loading: false,
			permanent_delete: 0,
		};
	},
	methods: {
		delete_file() {
			this.loading = true;
			this.$store
				.dispatch(DELETE, {
					url: `files/${this.toBeDelete.uuid}/delete?permanent=${this.permanent_delete}`,
				})
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		if (this.isBin) {
			this.permanent_delete = 1;
		}
	},
};
</script>
