<template>
	<div>
		<Dialog class="{'pointer-none': new_folder_dialog}" :dialog="moveDialog">
			<template v-slot:title>
				Move to<span v-if="output && output.name"
					><v-icon class="mx-2" color="blue darken-4">mdi-chevron-right</v-icon>{{ output.name }}</span
				>
			</template>
			<template v-slot:body>
				<div class="w-100 text-center my-4" v-if="t_loading">
					<v-progress-circular
						:size="70"
						:width="7"
						color="blue darken-4"
						indeterminate
					></v-progress-circular>
				</div>
				<template v-else>
					<v-treeview
						v-model="tree"
						:items="items"
						activatable
						item-key="id"
						return-object
						hoverable
						v-on:update:active="update_tree($event)"
					>
						<template v-slot:prepend="{ item, open }">
							<v-icon>
								{{ open ? "mdi-folder-open" : "mdi-folder" }}
							</v-icon>
						</template>
					</v-treeview>
				</template>
			</template>
			<template v-slot:action>
				<v-layout>
					<v-flex md6 class="text-left">
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on:click="new_folder_dialog = true"
									v-on="on"
									depressed
									tile
									color="blue darken-4 white--text"
									>Create&nbsp;&nbsp;<v-icon>mdi-folder-plus</v-icon></v-btn
								>
							</template>
							<span>Click here to create new folder</span>
						</v-tooltip>
					</v-flex>
					<v-flex md6 class="text-right">
						<v-btn :disabled="loading" depressed class="mr-2" tile v-on:click="$emit('close', true)"
							>Cancel</v-btn
						>
						<v-btn
							:disabled="!output.id || loading"
							:loading="loading"
							depressed
							tile
							color="blue darken-4 white--text"
							v-on:click="patch_folder_move()"
						>
							<template v-if="output.children.length">Move</template>
							<template v-else>Move Here</template>
						</v-btn>
					</v-flex>
				</v-layout>
			</template>
		</Dialog>
		<new-folder-dialog
			:new-folder-dialog="new_folder_dialog"
			:linked-id="output.id"
			v-on:close="new_folder_dialog = false"
			v-on:success="get_folder_list()"
		></new-folder-dialog>
	</div>
</template>

<script>
import { head } from "lodash";
import Dialog from "@/view/components/Dialog";
import NewFolderDialog from "@/view/components/NewFolderDialog";
import { GET, PATCH } from "@/core/services/store/request.module";

export default {
	name: "folder-dialog",
	props: {
		moveDialog: {
			type: Boolean,
			default: false,
		},
		toBeMove: {
			type: Object,
			default() {
				return {
					uuid: null,
					type: null,
					name: null,
					created: {
						datetime: null,
					},
				};
			},
		},
	},
	watch: {
		moveDialog(param) {
			if (param) {
				this.init();
			}
		},
	},
	data() {
		return {
			tree: [],
			items: [],
			loading: false,
			t_loading: false,
			new_folder_dialog: false,
			output: {
				id: 0,
				name: null,
				children: [],
			},
		};
	},
	methods: {
		init() {
			this.tree = [];
			this.items = [];
			this.loading = false;
			this.new_folder_dialog = false;
			this.output = {
				id: 0,
				name: null,
				children: [],
			};
			this.get_folder_list();
		},
		update_tree(param) {
			this.output = head(param);
			this.$emit("input", this.output);
			this.$emit("change", this.output);
		},
		get_folder_list() {
			this.t_loading = true;
			this.$store
				.dispatch(GET, { url: "folders" })
				.then((output) => {
					this.items = output;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.t_loading = false;
				});
		},
		patch_folder_move() {
			this.loading = true;
			this.$store
				.dispatch(PATCH, { url: `files/${this.toBeMove.uuid}/move`, data: this.output })
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
	components: {
		Dialog,
		"new-folder-dialog": NewFolderDialog,
	},
	mounted() {
		this.init();
	},
};
</script>
